import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss'],
})
export class PagetitleComponent implements OnInit {
  @Input() breadcrumbItems: Array<{}>;
  @Input() title: string;
  @Input() Subtitle: string;

  constructor(public router: Router) {}
  onLink(path) {
    this.router.navigateByUrl(path);
  }
  ngOnInit() {}
}
