import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatepresidentaddComponent } from './state-president/statepresidentadd/statepresidentadd.component';
import { StatepresidentviewComponent } from './state-president/statepresidentview/statepresidentview.component';
import { StateinchargeaddComponent } from './state-incharge/stateinchargeadd/stateinchargeadd.component';
import { StateinchargeviewComponent } from './state-incharge/stateinchargeview/stateinchargeview.component';
import { JointsecretoryviewComponent } from './joint-secretory/jointsecretoryview/jointsecretoryview.component';
import { JointsecretoryaddComponent } from './joint-secretory/jointsecretoryadd/jointsecretoryadd.component';
import { AiccusersaddComponent } from './aiccusers/aiccusersadd/aiccusersadd.component';
import { AiccusersviewComponent } from './aiccusers/aiccusersview/aiccusersview.component';

const routes: Routes = [
    {path: 'view-state-president', component: StatepresidentviewComponent},
    {path: 'statepresidentadd', component: StatepresidentaddComponent},
    {path: 'statepresidentedit/:id/:stateid/:des', component: StatepresidentaddComponent},
    {path: 'stateinchargeview', component: StateinchargeviewComponent},
    {path: 'stateinchargeadd', component: StateinchargeaddComponent},
    {path: 'stateinchargeedit/:id/:stateid/:des', component: StateinchargeaddComponent},
    {path: 'jointsecretoryview', component: JointsecretoryviewComponent},
    {path: 'jointsecretoryadd', component: JointsecretoryaddComponent},
    {path: 'jointsecretoryedit/:id/:stateid', component: JointsecretoryaddComponent},
    {path: 'aiccusersadd', component: AiccusersaddComponent},
    {path: 'aiccusersedit/:des', component: AiccusersaddComponent},
    {path: 'aiccusersview', component: AiccusersviewComponent}
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganogramuserRoutingModule { }
