import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JointsecretoryaddComponent } from './joint-secretory/jointsecretoryadd/jointsecretoryadd.component';
import { JointsecretoryviewComponent } from './joint-secretory/jointsecretoryview/jointsecretoryview.component';
import { StateinchargeaddComponent } from './state-incharge/stateinchargeadd/stateinchargeadd.component';
import { StateinchargeviewComponent } from './state-incharge/stateinchargeview/stateinchargeview.component';
import { StatepresidentaddComponent } from './state-president/statepresidentadd/statepresidentadd.component';
import { StatepresidentviewComponent } from './state-president/statepresidentview/statepresidentview.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { OrganogramuserRoutingModule } from './organogramuser-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { AiccusersaddComponent } from './aiccusers/aiccusersadd/aiccusersadd.component'
import { AiccusersviewComponent } from './aiccusers/aiccusersview/aiccusersview.component'
@NgModule({
  declarations: [
    JointsecretoryaddComponent,
    JointsecretoryviewComponent,
    StateinchargeaddComponent,
    StateinchargeviewComponent,
    StatepresidentaddComponent,
    StatepresidentviewComponent,
    AiccusersaddComponent,
    AiccusersviewComponent,
   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OrganogramuserRoutingModule,
    NgSelectModule,
    DataTablesModule
  ]
})
export class OrganogramuserModule { }
