import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-portlet-new',
  templateUrl: './portlet-new.component.html',
  styleUrls: ['./portlet-new.component.scss'],
})
export class PortletNewComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() collapseShow: string;
  @Input() id: number;
  @Input() color: string;
  @Input() text: string;
  @Input() headerClass: string;
  @Input() collapsed: number;
  @Input() loading: number;
  @Input() anotherTitle: string;
  @Input() subTitle: string;
  @Input() Icon: string;
  @Input() Icons: string;
  @Input() className: string;
  @Input() bgColor: string;
  @Input() width: string;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onContentRefresh: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onLoad: EventEmitter<any> = new EventEmitter();

  isLoading: boolean;
  isVisible: boolean;
  isCollapsed: boolean;
  constructor() {}

  ngOnInit() {
    // set the value
    // tslint:disable-next-line: triple-equals
    if (this.collapsed == 1) {
      this.isCollapsed = false;
    } else {
      this.isCollapsed = true;
    }
    // tslint:disable-next-line: triple-equals
    if (this.loading == 0) {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }

    this.isVisible = true;
  }
  ngOnChanges() {
    this.ngOnInit();
  }
  /**
   * Refreshes the content
   */
  refreshContent() {
    this.isLoading = true;

    // event emit to let parent know about data refresh
    this.onContentRefresh.emit();

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  /**
   * Removes self from dom
   */
  remove() {
    this.isVisible = false;
  }

  onHeaderClick(id) {
    if (this.isCollapsed) {
      this.onLoad.emit(id);
    }
    this.isCollapsed = !this.isCollapsed;
  }
}
