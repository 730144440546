import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CookieService } from '../core/services/cookie.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
const httpOptionsURLEncoded = {
  headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
};
const httpFileUploadOptions = {
  headers: new HttpHeaders(),
};

//const baseurl = `http://devapi.proplegit.com/`;
 //const baseurl = `https://qaapi.proplegit.com/`;
 // const baseurl = `https://devnpoapi.proplegit.com/`;
//  const dynamicURL = `https://qads.proplegit.com/api`;
 const dynamicURL = `https://ds.proplegit.com/api`;

// const baseurl = `http://devapi.proplegit.com/`;
//  const baseurl = `https://preapi.proplegit.com/`;
 const baseurl = `https://api.proplegit.com/`;
// const baseurl = `http://localhost:3001/`;
// const localurl = `http://localhost:3000/api/`;
const pythonUrl = `https://eppaicc.proplegit.com/`; //live
// const pythonUrl = `https://pydev9.proplegit.com/`;

//  const baseurl = `https://preapi.proplegit.com/`;
const getfinYearurl = `${baseurl}api/FinancialYear/list`;
const apiUrl = `${baseurl}api/`;
const getJointSecretary = `${apiUrl}get/StatePresident`;
const register = `${apiUrl}login/register`;
const generateOTP = `${apiUrl}generate/otp/`;
const validateOTP = `${apiUrl}validate/otp/`;
const login = `${apiUrl}login/verify`;
const stateWiseProperty = `${apiUrl}property/list/`;
const PropertyListByState = `${apiUrl}property/list/`;
const deleteProperty = `${apiUrl}property/delete/`;
const stateInfo = `${apiUrl}state/list`;
const count = `${apiUrl}property/dashboard/count`;
const getStatesList = `${apiUrl}state/list`;
const getCoorespondenceStates = `${apiUrl}property/StateCorrespondent`;
const addCorrespondenceDocumenturl = `${apiUrl}property/StateCorrespondent/`
const getCorrespondenceDocumentStates = `${apiUrl}property/StateCorrespondent/document/`
const forgotPassword = `${apiUrl}Forgot/Password`;
const loginOTPVerify = `${apiUrl}login/otp/verify`;
const propertyTaxType = `${apiUrl}Property/Tax/List/`;
const FinancialYearType = `${apiUrl}FinancialYear/list`;
const generateinvoice = `${apiUrl}Property/rent/generate/invoice/`;
const uploadInvoice = `${apiUrl}property/rent/Upload/invoice/`;
const generateReceipt = `${apiUrl}property/rent/generate/receipt/`;
const getLoanType = `${apiUrl}loan/application/types`;
const getLoanpropertyType = `${apiUrl}loan/Property/types`;
const addLoanApplication = `${apiUrl}loan/application/create`;
const getApplicationInformation = `${apiUrl}loan/application/View/details/`;
const getDocumentList = `${apiUrl}loan/application/Documents/AppID/`;
const savePVR = `${apiUrl}loan/pvr/createBy/`;
const getPVRData = `${apiUrl}loan/pvr/View/`;
const getPVRBankList = `${apiUrl}loan/bank/list`;
const UploadPVR = `${apiUrl}loan/pvr/Genrate/`;
const assignLawyer = `${apiUrl}loan/assign/lawyer/`;
const markasReviewed = `${apiUrl}loan/property/document/reviewed/`;
const fordemo = `${apiUrl}loan/Property/all/Document/Upload/`;
const fordemochangestatus = `${apiUrl}loan/application/status/`;
const Dashboard = `${apiUrl}loan/Dashboard/Count`;
const cancelContract = `${apiUrl}property/tenant/ContractCancel/`;
const propertyTaxAlert = `${apiUrl}property/tax/notification/Property/`;
const propertyRentAlert = `${apiUrl}property/rent/notification/Property/`;
const propertyLegalcaseAlert = `${apiUrl}property/legalCase/notification/Property/`;
const propertyCaseDetails = `${apiUrl}property/case/details/`;
const propertyCaseTypes = `${apiUrl}property/legalcase/types/list`;
const generatePVR = `${apiUrl}loan/pvr/Genrate/merge/`;
const getallLoanID = `${apiUrl}loan/application/ALL/ID`;
const resendLoginOPT = `${apiUrl}single/generate/otp/`;
const excelExport = `${apiUrl}area/list/Distict/`;
const UserList = `${apiUrl}user/list/`;
const loanTypePVRStatus = `${apiUrl}loan/Dashboard/loantype/PVR/status/`;
const trusteeType = `${apiUrl}trust/user/type`;
const addTrust = `${apiUrl}trust/add`;
const viewTrust = `${apiUrl}trust/view/`;
const selectExistingProperty = `${apiUrl}trust/property/add`;
const removePropertyFromTrust = `${apiUrl}trust/property/remove/`;
const trustAddMeeting = `${apiUrl}trust/add/meeting/`;
const cancelMeeting = `${apiUrl}trust/meeting/`;
const addFund = `${apiUrl}trust/add/Fund`;
const propertyDocumentType = `${apiUrl}property/document/type/list`;
const addAudit = `${apiUrl}trust/add/Audit`;
const addExamCertificate = `${apiUrl}trust/add/ExemCertificate`;
const postitreturnurl = `${apiUrl}trust/add/ITReturn`;
const getlawyerForItReturns = `${apiUrl}trust/user/`;
const getitreturnlisturl = `${apiUrl}trust/ITReturn/list`;
const updateMeetingurl = `${apiUrl}trust/meeting/update`;
const uploadtrustdomenturl = `${apiUrl}trust/document/add/`;
const getTrustDocumenturl = `${apiUrl}trust/document/View/`;
const updateTrustUrl = `${apiUrl}trust/edit/`;
const updateTrustUsers = `${apiUrl}trust/user/`;
const trustMeetingNotificationurl = `${apiUrl}trust/meeting/notification/TrustID/`;
const trustAuditNotification = `${apiUrl}trust/audit/notification/TrustID/`;
const trustFundNotification = `${apiUrl}trust/fund/notification/TrustID/`;
const trustITReturnNotification = `${apiUrl}trust/ITReturn/notification/TrustID/`;
const AddTrustee = `${apiUrl}trust/trustee/add/`;
const getDropDownData = `${apiUrl}property/DocumentType/`;
const ExemptionNotificationurl = `${apiUrl}trust/exemption/notification/TrustID/`;
const propertyLegalCaseTypes = `${apiUrl}property/legalcase/Ongoing/Count/UserByState/`;
const propertyTaxTypes = `${apiUrl}property/tax/Count/UserByState/`;
const propertyRentTypes = `${apiUrl}property/Rent/Count/UserByState/`;
const stateWisePropertyes = `${apiUrl}property/Count/UserByState/`;
const stateWiseTrusts = `${apiUrl}property/dashboard/trustForWeb/property/`;
const propertywisetrust = `${apiUrl}property/dashboard/trust`;

const getuserbycompanyid = `${baseurl}api/user/list/company/`;
const stateapi = `${baseurl}api/statecheck/list/`;
const user = `${baseurl}api/user/list/`;
const addUserStates = `${baseurl}api/stateid/id/`;
const approveuser = `${baseurl}api/user/approve/`;
const stateInfouser = `${apiUrl}state/list/rights/`;
const TrustCount = `${apiUrl}property/dashboard/count/`;
const TrustCountForWeb = `${apiUrl}property/dashboard/countForWeb/`;
const addStatePresident = `${apiUrl}add/StatePresident`;
const editStatePresident = `${apiUrl}update/StatePresident`;
const viewStatePresident = `${apiUrl}get/StatePresident`;
const editAiccUsers = `${apiUrl}updateSPT/`;
const statePresident = `${apiUrl}get/StatePresident`;
const propertySearch = `${apiUrl}property/Count/UserByState/`;
const addEPP = `${pythonUrl}AddEPP`
const getEPPList = `${pythonUrl}EPPStatewisecount`
const PropertyWise = `${pythonUrl}Propertywise`
const UpdateProperty = `${pythonUrl}EditProperty`
const UpdateEPPSurvey = `${pythonUrl}EditEPP`
const MovetoEPP = `${pythonUrl}MoveToEPP`
const EPPStatement = `${pythonUrl}Statements`
const RmeinderList = `${apiUrl}property/reminder/list`
const TaxReminderSubList = `${apiUrl}property/tax/reminder/User/`
const LegalCaseReminderSubList = `${apiUrl}property/legalcase/reminder/User/`
const RentReminderSubList = `${apiUrl}property/rent/reminder/User/`
const taxReminder = `${apiUrl}property/tax/reminder/notification/User/`
const LegalCaseReminder =  `${apiUrl}property/legalcase/reminder/notification/User/`
const RentReminder =  `${apiUrl}property/rent/reminder/notification/User/`

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  getExemptionNotification(TrustID) {
    return this.http.get(`${ExemptionNotificationurl}${TrustID}`, httpOptions);
  }
  GetDropDownValue(DropDownID) {
    return this.http.get(`${getDropDownData}${DropDownID}`, httpOptions);
  }
  ADDTrustee(body, TrustID) {
    return this.http.post(`${AddTrustee}${TrustID}`, body, httpOptions);
  }
  RmeinderList(){
    return this.http.get(`${RmeinderList}`)
  }
  TaxReminderSubList(UserID){
    return this.http.get(`${TaxReminderSubList}${UserID}`)
  }
  RentReminderSubList(UserID){
    return this.http.get(`${RentReminderSubList}${UserID}`)
  }
  LegalCaseReminderSubList(UserID){
    return this.http.get(`${LegalCaseReminderSubList}${UserID}`)
  }
  taxReminder(UserID,DayIF){
    return this.http.get(`${taxReminder}${UserID}/${DayIF}`)
  }
  RentReminder(UserID,DayIF){
    return this.http.get(`${RentReminder}${UserID}/${DayIF}`)
  }
  LegalCaseReminder(UserID,DayIF){
    return this.http.get(`${LegalCaseReminder}${UserID}/${DayIF}`)
  }
  GetTrustMeetingAlert(TrustID): any {
    return this.http.get(
      `${trustMeetingNotificationurl}${TrustID}`,
      httpOptions
    );
  }
  EPPStatement(){
    return EPPStatement
  }
  GetTrustFundAlert(TrustID): any {
    return this.http.get(`${trustFundNotification}${TrustID}`, httpOptions);
  }
  GetTrustAuditAlert(TrustID): any {
    return this.http.get(`${trustAuditNotification}${TrustID}`, httpOptions);
  }
  GetTrustItReturnAlert(TrustID): any {
    return this.http.get(`${trustITReturnNotification}${TrustID}`, httpOptions);
  }
  MovetoEPP(data){
    return this.http.post(`${MovetoEPP}`,data)
  }
  updateTrust(data, TrustID) {
    return this.http.post(`${updateTrustUrl}${TrustID}`, data, httpOptions);
  }
  updateTrustUsers(data, TrusteeUserID) {
    return this.http.post(
      `${updateTrustUsers}${TrusteeUserID}`,
      data,
      httpOptions
    );
  }
  UpdateProperty(data){
   return this.http.post(`${UpdateProperty}`,data)
  }
  addCorrespondenceDocument(stateID, userID, body) {
    return this.http.post(addCorrespondenceDocumenturl + stateID, body, httpFileUploadOptions);
  }
  getStatesList() {
    return this.http.get(`${getStatesList}`, httpOptions);
  }
  getCorrespondenceStates() {
    return this.http.get(`${getCoorespondenceStates}`, httpOptions);
  }
  getCorrespondenceDocumentStates(id, param) {
    return this.http.get(`${getCorrespondenceDocumentStates}` + id + param, httpOptions);
  }
  UpdateEPPSurvey(data){
    return this.http.post(`${UpdateEPPSurvey}`,data)
  }
  getTrustDocument(TrustID) {
    return this.http.get(`${getTrustDocumenturl}${TrustID}`, httpOptions);
  }
  uploadTrustDocument(data, TrustID) {
    return this.http.post(
      `${uploadtrustdomenturl}${TrustID}`,
      data,
      httpFileUploadOptions
    );
  }
  updateMeeting(body) {
    return this.http.post(updateMeetingurl, body, httpFileUploadOptions);
  }
  getITReturn(TrustID) {
    return this.http.get(`${getitreturnlisturl}${TrustID}`, httpOptions);
  }
  getTrustUserfor(id) {
    return this.http.get(`${getlawyerForItReturns}${id}`, httpOptions);
  }
  POSTITReturn(body) {
    return this.http.post(postitreturnurl, body, httpFileUploadOptions);
  }
  getFiynearList() {
    return this.http.get(getfinYearurl, httpOptions);
  }
  addAudit(arg0: any) {
    throw new Error('Method not implemented.');
  }
  addFund(trustID: number, arg1: any) {
    throw new Error('Method not implemented.');
  }
  addExamCertificate(trustID: number, arg1: any) {
    throw new Error('Method not implemented.');
  }
  private userID: number;
  private user;
  private otpID;
  // tslint:disable-next-line: variable-name
  private _refresh = new Subject<void>();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) { }

  userRegister(userData): any {
    return this.http.post(register, userData, httpOptions);
  }
  GetBaseUrl() {
    return apiUrl;
  }

  GetJointSecretary(param):any{
    return this.http.get(`${getJointSecretary}${param}`);
  }

  getapiUrl() {
    return apiUrl;
  }
  getUserID() {
    return this.userID;
  }
  reFresh() {
    return this._refresh;
  }

  getDashboard(): any {
    return this.http.get(count, httpOptions);
  }
  getTrustDashboard(userId, AfterDays: any): any {
    return this.http.get(TrustCount + userId + '/' + AfterDays, httpOptions);
  }
  getTrustDashboardWeb(userId, AfterDays: any): any {
    return this.http.get(
      TrustCountForWeb + userId + '/' + AfterDays,
      httpOptions
    );
  }

  setUserID(userid: number) {
    this.userID = userid;
  }
  setOTPID(otp: number) {
    this.otpID = otp;
  }
  getOTPID() {
    return this.otpID;
  }

  generateOTP(id, data): any {
    return this.http.post(`${generateOTP}${id}`, data, httpOptions);
  }

  validateOTP(data): any {
    return this.http.post(validateOTP, data, httpOptions);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }

  login(data) {
    return this.http.post<any>(login, data, httpOptions);
    // .pipe(map(user => {
    // tslint:disable-next-line: triple-equals
    //   if (user.error && user.status != 200) {
    //     return user;
    //   } else {
    //     this.user = user;
    //     this.cookieService.setCookie('currentUser', JSON.stringify(user.data[0]), 1);
    //     return user;
    //   }
    // }));
  }
  loginOTPVerify(data) {
    return this.http.post<any>(loginOTPVerify, data, httpOptions).pipe(
      map((user) => {
        // tslint:disable-next-line: triple-equals
        if (user.error && user.status != 200) {
          return user;
        } else {
          this.user = user;
          this.cookieService.setCookie(
            'currentUser',
            JSON.stringify(user.data[0]),
            1
          );
          return user;
        }
      })
    );
  }

  saveCookie(data) {
    this.cookieService.setCookie('currentUser', JSON.stringify(data), 1);
  }

  logout() {
    this.cookieService.deleteCookie('currentUser');
    this.user = null;
  }
  getcurrentUser() {
    return JSON.parse(this.cookieService.getCookie('currentUser'));
  }

  getStateWisePropertyCount(userId): any {
    return this.http.get(stateWiseProperty + userId, httpOptions);
  }

  getPropertyListByState(id): any {
    return this.http.get(`${PropertyListByState}${id}`, httpOptions);
  }
  deleteProperty(id): any {
    return this.http.delete(`${deleteProperty}${id}`, httpOptions);
  }
  states() {
    return this.http.get<any>(stateInfo, httpOptions);
  }
  dynamicData(URL): any {
    return this.http.get(`${URL}`, httpOptions);
  }
  talukas(id): any {
    return this.http.get(`${apiUrl}taluka/list/${id}`, httpOptions);
  }
  villages(id): any {
    return this.http.get(`${apiUrl}village/list/${id}`, httpOptions);
  }
  districts(id): any {
    return this.http.get(`${dynamicURL}/district/list/${id}`, httpOptions);
  }
  getDynamicFields(data) {
    return this.http.post(`${dynamicURL}/Dynamicdata`, data, httpOptionsURLEncoded);
  }
  PropertyWise(){
    return this.http.get(`${PropertyWise}`)
  }
  PropertyBaseDocWise(){
    return PropertyWise
  }
  PropertyIDWise(StateId,baseDoc){
    return this.http.get(`${PropertyWise}/${StateId}/${baseDoc}`)
  }
  PropertyStateWise(Stateid){
    return this.http.get(`${PropertyWise}/${Stateid}`)
  }
  getStateBaseDocTypeByStateID(StateID) {
    return this.http.get(`${dynamicURL}/statebasedoctype/list/${StateID}`, httpOptions);
  }
  addproperty(data): any {
    return this.http.post(`${apiUrl}property/add`, data, httpOptions);
  }
  propertytype(): any {
    return this.http.get(`${apiUrl}propertytype/list`, httpOptions);
  }
  area(search): any {
    return this.http.get(`${apiUrl}area/list/` + search, httpOptions);
  }
  areabyid(id): any {
    return this.http.get(`${apiUrl}area/view/${id}`);
  }
  Addphotograph(PropertyID, data): any {
    return this.http.post(
      `${apiUrl}property/${PropertyID}/document/add`,
      data,
      httpFileUploadOptions
    );
  }

  listphotograph(PropertyID): any {
    return this.http
      .get<any>(`${apiUrl}property/${PropertyID}/photograph`)
      .pipe(
        map((responce) => {
          if (responce.error && responce.status !== 200) {
            return responce;
          } else {
            return responce.data;
          }
        })
      );
  }

  viewproperty(propertyID) {
    return this.http.get<any>(
      `${apiUrl}property/view/${propertyID}`,
      httpOptions
    );
  }
  editproperty(propertyID, data) {
    return this.http.put<any>(
      `${apiUrl}property/update/${propertyID}`,
      data,
      httpOptions
    );
  }
  addtax(PropertyID, data): any {
    return this.http.post(
      `${apiUrl}property/${PropertyID}/tax/add`,
      data,
      httpFileUploadOptions
    );
  }
  addtaxconfirm(PropertyID, data): any {
    return this.http.post(
      `${apiUrl}property/${PropertyID}/tax/add?FileExistenceCheck=1`,
      data,
      httpFileUploadOptions
    );
  }
  listLawyers(): any {
    return this.http.get<any>(`${apiUrl}lawyer/list`, httpOptions);
  }
  deleteLawyer(LawyerId): any {
    return this.http.delete<any>(`${apiUrl}lawyer/delete/${LawyerId}`);
  }
  addLawyer(data): any {
    return this.http.post<any>(`${apiUrl}lawyer/add`, data, httpOptions);
  }
  viewLawyer(LawyerId) {
    return this.http.get<any>(`${apiUrl}lawyer/view/${LawyerId}`, httpOptions);
  }
  updateLawyer(data, LawyerId): any {
    return this.http.put<any>(
      `${apiUrl}lawyer/update/${LawyerId}`,
      data,
      httpOptions
    );
  }
  listTenant(id): any {
    return this.http.get<any>(`${apiUrl}property/${id}/rent/list`, httpOptions);
  }
  deleteTenant(PropertyRentId): any {
    return this.http.delete<any>(
      `${apiUrl}property/rent/delete/${PropertyRentId}`
    );
  }
  addTenant(data, id): any {
    return this.http.post<any>(
      `${apiUrl}property/${id}/tenant/add`,
      data,
      httpFileUploadOptions
    );
  }
  viewTenant(PropertyRentId) {
    return this.http.get<any>(
      `${apiUrl}property/rent/view/${PropertyRentId}`,
      httpOptions
    );
  }
  updateTenant(data, PropertyRentId): any {
    return this.http.put<any>(
      `${apiUrl}property/rent/update/${PropertyRentId}`,
      data,
      httpOptions
    );
  }
  fetchstatelist() {
    return this.http.get<any>(stateInfo, httpOptions);
  }
  areabystateid(StateId, Search, DistrictId?): any {
    if (DistrictId) {
      return this.http.get(
        `${apiUrl}area/list/state/${StateId}/district/area/${Search}?DistrictId=${DistrictId}`
      );
    } else {
      return this.http.get(
        `${apiUrl}area/list/state/${StateId}/district/area/${Search}`
      );
    }
  }

  uploadTaxReceipt(propertyid, taxid, data, confirm = ''): any {
    return this.http.post(
      `${apiUrl}property/${propertyid}/tax/${taxid}/receipt/upload${confirm}`,
      data,
      httpFileUploadOptions
    );
  }
  uploadRentReceipt(rentid, data, confirm = ''): any {
    return this.http.post(
      `${apiUrl}property/rent/Upload/receipt/${rentid}${confirm}`,
      data,
      httpFileUploadOptions
    );
  }
  getDocument(propertyid, id): any {
    return this.http.get(
      `${apiUrl}property/${propertyid}/Document/view/${id}`,
      httpOptions
    );
  }
  forgotPassword(data): any {
    return this.http.post(`${forgotPassword}`, data, httpOptions);
  }
  submitForgotPassword(data): any {
    return this.http.put(`${forgotPassword}/update`, data, httpOptions);
  }
  getpropertytaxtypeList(id): any {
    return this.http.get(`${propertyTaxType}${id}`, httpOptions);
  }
  getFinancialYearList(): any {
    return this.http.get(`${FinancialYearType}`, httpOptions);
  }
  getRentList(id): any {
    return this.http.get(`${apiUrl}property/rent/view/${id}`, httpOptions);
  }
  GenerateInvoice(id, userId): any {
    return this.http.post(
      `${generateinvoice}${id}`,
      { CreatedBy: userId },
      httpOptions
    );
  }
  UploadInvoice(PropertyRentID, data): any {
    return this.http.post(
      `${uploadInvoice}${PropertyRentID}`,
      data,
      httpFileUploadOptions
    );
  }
  UploadInvoiceConfirm(PropertyRentID, data): any {
    return this.http.post(
      `${uploadInvoice}${PropertyRentID}?FileExistenceCheck=0`,
      data,
      httpFileUploadOptions
    );
  }
  GenerateReceipt(id, data): any {
    return this.http.post(`${generateReceipt}${id}`, data, httpOptions);
  }
  addLegalCase(PropertyID, data) {
    return this.http.post<any>(
      `${apiUrl}property/${PropertyID}/case/add`,
      data,
      httpOptions
    );
  }
  listLegalcase(id): any {
    return this.http.get<any>(`${apiUrl}property/${id}/case/list`, httpOptions);
  }
  GetLoanTypes() {
    return this.http.get<any>(`${getLoanType}`, httpOptions);
  }
  GetLoanPropertyTypes() {
    return this.http.get<any>(`${getLoanpropertyType}`, httpOptions);
  }
  AddLoanApplication(data) {
    return this.http.post<any>(`${addLoanApplication}`, data, httpOptions);
  }
  GetApplicationInformation(id) {
    return this.http.get<any>(`${getApplicationInformation}${id}`, httpOptions);
  }
  GetDocumentList(id): any {
    return this.http.get<any>(`${getDocumentList}${id}`, httpOptions);
  }
  SavePVR(data, id) {
    return this.http.post<any>(`${savePVR}${id}`, data, httpOptions);
  }
  GetPVRData(id): any {
    return this.http.get<any>(`${getPVRData}${id}`, httpOptions);
  }
  GeneratePVR(appid): any {
    return this.http.get<any>(`${generatePVR}${appid}`, httpOptions);
  }
  GetPVRBankList(): any {
    return this.http.get<any>(`${getPVRBankList}`, httpOptions);
  }
  UploadPVR(Appid, data): any {
    return this.http.post(`${UploadPVR}${Appid}`, data, httpFileUploadOptions);
  }
  AssignLawyer(AppId, LawyerID): any {
    return this.http.put(
      `${assignLawyer}${AppId}/${LawyerID}`,
      null,
      httpOptions
    );
  }
  MarkAsReviewed(PropertyId, DocumentId) {
    return this.http.put(
      `${markasReviewed}${PropertyId}/${DocumentId}`,
      null,
      httpOptions
    );
  }
  ForDemo(AppID): any {
    return this.http.get(`${fordemo}${AppID}`, httpOptions);
  }
  getLoanDashboard(data): any {
    return this.http.post(Dashboard, data, httpOptions);
  }
  changeStatus(appID, status): any {
    return this.http.put(
      fordemochangestatus + appID,
      { ApplicationStatus: status },
      httpOptions
    );
  }
  CancelRentContract(tenantId): any {
    return this.http.put(`${cancelContract}${tenantId}`, null, httpOptions);
  }
  GetPropertyTaxAlert(PropertyID): any {
    return this.http.get(`${propertyTaxAlert}${PropertyID}`, httpOptions);
  }
  GetPropertyRentAlert(PropertyID): any {
    return this.http.get(`${propertyRentAlert}${PropertyID}`, httpOptions);
  }
  GetPropertyLegalcaseAlert(PropertyID): any {
    return this.http.get(`${propertyLegalcaseAlert}${PropertyID}`, httpOptions);
  }
  GetPropertyCaseDetails(caseID): any {
    return this.http.get(`${propertyCaseDetails}${caseID}`, httpOptions);
  }
  GetPropertyLegalCaseTypes(): any {
    return this.http.get(propertyCaseTypes, httpOptions);
  }
  AddLegalCaseAct(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/CaseActs`,
      data,
      httpOptions
    );
  }
  GetLegalCaseLastHearing(CaseID): any {
    return this.http.get(
      `${apiUrl}property/case/${CaseID}/last-hearing`,
      httpOptions
    );
  }
  AddLegalCaseHearing(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/next-hearing`,
      data,
      httpOptions
    );
  }
  AddPetitionerAndLawyer(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/petitioner`,
      data,
      httpOptions
    );
  }
  AddRespondentAndLawyer(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/respondent`,
      data,
      httpOptions
    );
  }
  GetPetitionerAndLawyer(CaseID): any {
    return this.http.get(
      `${apiUrl}property/case/${CaseID}/petitionersandlawyer`,
      httpOptions
    );
  }
  GetRespondentAndLawyer(CaseID): any {
    return this.http.get(
      `${apiUrl}property/case/${CaseID}/respondentsandlawyer`,
      httpOptions
    );
  }
  GetAllLoanID(
    StateName,
    DistrictName,
    TalukaName,
    VillageName,
    BankName,
    LoanType,
    LoanPropertyType
  ): any {
    return this.http.post(
      `${getallLoanID}`,
      {
        StateName,
        DistrictName,
        TalukaName,
        VillageName,
        BankName,
        LoanType,
        LoanPropertyType,
      },
      httpOptions
    );
  }
  ChangeECResponce(CaseID): any {
    return this.http.put(
      `${apiUrl}loan/application/ECRequest/${CaseID}`,
      null,
      httpOptions
    );
  }
  ResendLoginOTP(UserID): any {
    return this.http.post(`${resendLoginOPT}${UserID}`, null, httpOptions);
  }
  GetExcelExportData(StateID): any {
    return this.http.get(`${excelExport}${StateID}`, httpOptions);
  }
  GetUser(UserID): any {
    return this.http.get(`${UserList}${UserID}`, httpOptions);
  }
  GetLoanTypePVRStatus(UserID): any {
    return this.http.get(`${loanTypePVRStatus}${UserID}`, httpOptions);
  }
  UploadPhotosAndVideos(propertyID, Data, confirm = ''): any {
    return this.http.post(
      `${apiUrl}property/${propertyID}/photograph/add${confirm}`,
      Data,
      httpFileUploadOptions
    );
  }
  DisposeLegalCase(LegalCaseID, Data): any {
    return this.http.post(
      `${apiUrl}property/legal/Case/Decision/Document/${LegalCaseID}`,
      Data,
      httpFileUploadOptions
    );
  }
  UploadLegalCaseDocument(LegalCaseID, Data, confirm = ''): any {
    return this.http.post(
      `${apiUrl}property/legal/Case/Document/${LegalCaseID}${confirm}`,
      Data,
      httpFileUploadOptions
    );
  }
  GetLegalCaseDocument(LegalCaseID): any {
    return this.http.get(
      `${apiUrl}property/legal/Case/View/Document/${LegalCaseID}`,
      httpOptions
    );
  }
  GetTrusteeTypes(): any {
    return this.http.get(`${trusteeType}`, httpOptions);
  }
  AddTrust(Data): any {
    return this.http.post(`${addTrust}`, Data, httpOptions);
  }
  GetTrustinfo(TrustID): any {
    return this.http.get(`${viewTrust}${TrustID}`, httpOptions);
  }
  SelectExistingProperty(TrustID, PropertyID, CreatedBy): any {
    return this.http.post(
      `${selectExistingProperty}`,
      { TrustID, PropertyID, CreatedBy },
      httpOptions
    );
  }
  RemovePropertyFromTrust(TrustID, PropertyID): any {
    return this.http.delete(
      `${removePropertyFromTrust}${TrustID}/${PropertyID}`,
      httpOptions
    );
  }
  AddMeeting(TrustID, Data): any {
    return this.http.post(
      `${trustAddMeeting}${TrustID}`,
      Data,
      httpFileUploadOptions
    );
  }
  CancelMEeting(MeetingID): any {
    return this.http.delete(`${cancelMeeting}${MeetingID}`, httpOptions);
  }
  CompleteMeeting(MeetingID, Data): any {
    return this.http.put(
      `${cancelMeeting}${MeetingID}`,
      Data,
      httpFileUploadOptions
    );
  }
  AddFund(data): any {
    return this.http.post(`${addFund}`, data, httpFileUploadOptions);
  }
  UploadTaxDemandNotice(propertyID, taxID, Data, confirm = ''): any {
    return this.http.put(
      `${apiUrl}property/${propertyID}/tax/update/${taxID}${confirm}`,
      Data,
      httpFileUploadOptions
    );
  }
  GetPropertyDocumentType(): any {
    return this.http.get(`${propertyDocumentType}`, httpOptions);
  }
  UploadPropertyDocumnent(propertyID, Data, confirm = ''): any {
    return this.http.post(
      `${apiUrl}property/${propertyID}/all/document/upload${confirm}`,
      Data,
      httpFileUploadOptions
    );
  }
  AddAudit(data): any {
    return this.http.post(`${addAudit}`, data, httpFileUploadOptions);
  }
  AddExamCertificate(data): any {
    return this.http.post(`${addExamCertificate}`, data, httpFileUploadOptions);
  }

  getStateWisePropertyLegalCaseCount(userId): any {
    return this.http.get(propertyLegalCaseTypes + userId, httpOptions);
  }

  getStateWisePropertyTaxCount(userId, AfterDays: any): any {
    return this.http.get(
      propertyTaxTypes + userId + '/' + AfterDays,
      httpOptions
    );
  }

  getStateWisePropertyRentCount(userId, AfterDays: any): any {
    return this.http.get(
      propertyRentTypes + userId + '/' + AfterDays,
      httpOptions
    );
  }

  getStateWisePropertyCountByState(userId): any {
    return this.http.get(stateWisePropertyes + userId, httpOptions);
  }
  getStateWiseTrustCountByState(userId): any {
    return this.http.get(stateWiseTrusts + userId, httpOptions);
  }

  getUserByCompanyID(CompanyID: any): any {
    return this.http.get<any>(`${getuserbycompanyid}` + CompanyID, httpOptions);
  }

  getTrustByPropertyID(PropertyID: any): any {
    return this.http.get<any>(
      `${propertywisetrust}/${PropertyID}`,
      httpOptions
    );
  }

  getState(UserID: any): any {
    return this.http.get<any>(`${stateapi}` + UserID, httpOptions);
  }

  getUser(UserID: any): any {
    return this.http.get<any>(`${user}` + UserID, httpOptions);
  }
  addUserStates(stateIDs: any, UserID: any): any {
    // console.log(stateIDs);
    return this.http.post<any>(
      `${addUserStates}` + UserID,
      stateIDs,
      httpOptions
    );
  }
  approveUser(UserID: any): any {
    return this.http.put<any>(`${approveuser}` + UserID, httpOptions);
  }

  fetchstatelistUser(UserID: any): any {
    return this.http.get<any>(`${stateInfouser}` + UserID, httpOptions);
  }

  addStatePresident(data) {
    return this.http.post(addStatePresident, data, httpOptions);
  }

  updateStatePresident(data, id, stateid) {
    return this.http.post(`${editStatePresident}/${id}/${stateid}`, data, httpOptions);
  }

  viewStatePresident(stateid) {
    return this.http.get(viewStatePresident + stateid);
  }

  updateAiccUsers(data, id) {
    return this.http.post(`${editAiccUsers}${id}`, data, httpOptions);
  }

  statePresident(param){
    return this.http.get(statePresident + param , httpOptions);
  }

  searchProperty(UserID,Search):any{
    return this.http.get(`${propertySearch}${UserID}${Search}`,httpOptions);
  }

  
  AddEPP(data) {
    return this.http.post(addEPP, data);
  }
  getEPPLists(){
    return getEPPList
  }
  getEPPList(){
    return this.http.get(getEPPList);
  }
  getEPPListPerState(stateID){
    return this.http.get(getEPPList+'/'+stateID);
  }
  getStatementsByDocType(stateID,id){
    return this.http.get(getEPPList+'/'+stateID+'/'+id);
  }
}
