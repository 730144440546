import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-statepresidentadd',
  templateUrl: './statepresidentadd.component.html',
  styleUrls: ['./statepresidentadd.component.scss']
})
export class StatepresidentaddComponent implements OnInit {

  statePresidentForm: FormGroup;
  submited = false;
  stateList: any;
  currentUser: any;
  userID: this;
  issubmited = false;
  statePresidentData: any;
  ID: any;
  StateID: any;
  Des:any;
  user: any;
  jointSecretaryList:any;

  constructor(
    private generalService: GeneralService,
    private cookie: CookieService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = this.generalService.getcurrentUser();
    this.statePresidentForm = new FormGroup({
      refID: new FormControl(null, Validators.required),
      UserID: new FormControl(this.user.UserID, Validators.required),
      StateID: new FormControl(null, Validators.required),
      Name: new FormControl('', [Validators.required]),
      EmailID : new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9._]+@[A-Za-z0-9]+\.[A-Za-z]{2,3}')]),
      MobileNumber: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{10}')]),
      Des: new FormControl('State President', Validators.required)
    });
  }

  ngOnInit() {
    this.generalService.GetJointSecretary('?Des=Joint Secretary').subscribe((response)=>{
      this.jointSecretaryList = response.data;
    });
    
    this.getStatelist();
    this.ID = this.route.snapshot.paramMap.get('id');
    this.StateID = this.route.snapshot.paramMap.get('stateid');
    this.Des = this.route.snapshot.paramMap.get('des');
    if (this.ID != null && this.StateID != null && this.Des != null) {
      const param = `?StateID=${this.StateID}&Des=${this.Des}`;
      this.generalService.viewStatePresident(param).subscribe((response: any) => {
        this.statePresidentData = response.data[0];
        this.statePresidentForm.patchValue(this.statePresidentData);
        this.statePresidentForm.controls.refID.setValue(this.statePresidentData.ref_ID);
        this.statePresidentForm.controls.StateID.disable();
      });
    }
  }

  getStatelist() {
    this.generalService
      .getStatesList()
      .subscribe((res: any) => {
        this.stateList = res.data;
      });
  }
  onSubmit() {
    this.submited = true;
    if (this.statePresidentForm.valid) {
      this.statePresidentForm.controls.StateID.enable();
      this.issubmited = true;
      if (this.ID != null && this.StateID != null) {
        // tslint:disable-next-line: max-line-length
        this.generalService.updateStatePresident(this.statePresidentForm.value, this.statePresidentData.ID, this.statePresidentData.StateID).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Updated',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/view-state-president']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
            this.statePresidentForm.controls.StateID.disable();
          }
        });
      } else {
        this.generalService.addStatePresident(this.statePresidentForm.value).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Uploaded',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/view-state-president']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
          }
        });
      }
    }
  }
  get f() {
    return this.statePresidentForm.controls;
  }
  onChangeState() {
    this.StateID = this.statePresidentForm.get('StateID').value;
  }

  onChangeReference(){
    
  }
}
