import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-jointsecretoryview',
  templateUrl: './jointsecretoryview.component.html',
  styleUrls: ['./jointsecretoryview.component.scss']
})
export class JointsecretoryviewComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  presidentList: any;
  constructor(
    private generalService: GeneralService,
    private cookie: CookieService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dtOptions = {
      ajax: {url: `${this.generalService.GetBaseUrl()}get/StatePresident?Des=Joint Secretary`}, responsive: true,
      columns: [
      {
        title: 'State Name',
        data: 'StateName'
      }, {
        title: 'Name',
        data: 'Name'
      }, {
        title: 'Email ID',
        data: 'EmailID'
      },
      {
        title: 'Mobile Number',
        data: 'MobileNumber'
      },
      {
        title: 'Action',
        render: (data: any, type: any, row: any) => {
          // tslint:disable-next-line: max-line-length
          return `<a class="btn text-primary updateJointSecretory" ><i class="mdi mdi-content-save-edit-outline" ID="${row.ID}"  StateID="${row.StateID}"></i></a>`;
        }
      }],
      order: [[0, 'desc']],
      columnDefs: [
        { orderable: false, targets: [4]},
        { orderable: true, targets: '_all' }],
      pagingType: 'full_numbers',
      drawCallback: () => {
        $('.updateJointSecretory').on('click', (e) => {
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/organogram-user/jointsecretoryedit' + '/' + e.target.getAttribute('ID') + '/' + e.target.getAttribute('StateID')]);
        });
      }
    };
  }

}
