import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portlet-trust',
  templateUrl: './portlet-trust.component.html',
  styleUrls: ['./portlet-trust.component.scss'],
})
export class PortletTrustComponent implements OnInit {
  @Input() title: string;
  @Input() collapseShow: string;
  @Input() id: number;
  @Input() color: string;
  @Input() text: string;
  @Input() headerClass: string;
  @Input() collapsed: number;
  @Input() loading: number;
  @Input() anotherTitle: string;
  @Input() Icon: string;
  @Input() SubIcon: string;
  @Input() className: string;
  @Input() bgColor: string;
  @Input() width: string;
  @Input() trustid: any;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onContentRefresh: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onLoad: EventEmitter<any> = new EventEmitter();

  isLoading: boolean;
  isVisible: boolean;
  isCollapsed: boolean;
  constructor(private router: Router) {}

  ngOnInit() {
    // set the value
    // tslint:disable-next-line: triple-equals
    if (this.collapsed == 1) {
      this.isCollapsed = false;
    } else {
      this.isCollapsed = true;
    }
    // tslint:disable-next-line: triple-equals
    if (this.loading == 0) {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }

    this.isVisible = true;
  }
  ngOnChanges() {
    this.ngOnInit();
  }
  /**
   * Refreshes the content
   */
  refreshContent() {
    this.isLoading = true;

    // event emit to let parent know about data refresh
    this.onContentRefresh.emit();

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  /**
   * Removes self from dom
   */
  remove() {
    this.isVisible = false;
  }

  onHeaderClick(id) {
    if (this.isCollapsed) {
      this.onLoad.emit(id);
    }
    this.isCollapsed = !this.isCollapsed;
  }
  redirectTrust() {
    // this.router.navigate(['property/view/']);
    this.router.navigate(['trust/view-trust/' + this.trustid]);
  }
}
