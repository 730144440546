import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stateinchargeadd',
  templateUrl: './stateinchargeadd.component.html',
  styleUrls: ['./stateinchargeadd.component.scss']
})
export class StateinchargeaddComponent implements OnInit {

  stateInchargeForm: FormGroup;
  submited = false;
  stateList: any;
  userID: this;
  issubmited = false;
  stateInchargeData: any;
  ID: any;
  StateID: any;
  Des:any;
  user: any;
  districtList:any;
  statePresidentData:any

  constructor(
    private generalService: GeneralService,
    private cookie: CookieService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = this.generalService.getcurrentUser();
    this.stateInchargeForm = new FormGroup({
      UserID: new FormControl(this.user.UserID, Validators.required),
      StateID: new FormControl(null, Validators.required),
      DistrictID: new FormControl(null, Validators.required),
      refID: new FormControl(null, Validators.required),
      Name: new FormControl('', [Validators.required]),
      EmailID : new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9._]+@[A-Za-z0-9]+\.[A-Za-z]{2,3}')]),
      MobileNumber: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{10}')]),
      Des: new FormControl('State Incharge', Validators.required)
    });
   }

  ngOnInit() {
    this.getStatelist();
    this.getStatePresident();
    this.ID = this.route.snapshot.paramMap.get('id');
    this.StateID = this.route.snapshot.paramMap.get('stateid');
    this.Des = this.route.snapshot.paramMap.get('des');
    if (this.ID != null && this.StateID != null && this.Des != null) {
      const param = `?StateID=${this.StateID}&Des=${this.Des}`;
      this.generalService.viewStatePresident(param).subscribe((response: any) => {
        this.stateInchargeData = response.data[0];
        this.stateInchargeForm.patchValue(this.stateInchargeData);
        this.stateInchargeForm.controls.DistrictID.setValue(this.stateInchargeData.DistrictName);
        this.stateInchargeForm.controls.refID.setValue(this.stateInchargeData.ref_ID);
        this.stateInchargeForm.controls.StateID.disable();
      });
    }
  }

  getStatelist() {
    this.generalService
      .getStatesList()
      .subscribe((res: any) => {
        this.stateList = res.data;
      });
  }

  getStatePresident(){
    let param = '?Des=State President';
    this.generalService.statePresident(param).subscribe((response:any) => {
    this.statePresidentData = response.data;
    })
  }

  onSubmit(){
    this.submited = true;
    if (this.stateInchargeForm.valid) {
      this.stateInchargeForm.controls.StateID.enable();
      this.issubmited = true;
      if (this.ID != null && this.StateID != null) {
        // tslint:disable-next-line: max-line-length
        this.generalService.updateStatePresident(this.stateInchargeForm.value, this.stateInchargeData.ID, this.stateInchargeData.StateID).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Updated',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/stateinchargeview']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
            this.stateInchargeForm.controls.StateID.disable();
          }
        });
      } else {
        this.generalService.addStatePresident(this.stateInchargeForm.value).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Uploaded',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/stateinchargeview']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
          }
        });
      }
    }

  }
  get f() {
    return this.stateInchargeForm.controls;
  }

  onChangeState(stateid){
    this.generalService.districts(stateid).subscribe((response : any) => {
      this.districtList = response.data;
    });
  }

  setstateID(){
    this.statePresidentData.forEach(element => {
      if(element.ID === this.stateInchargeForm.controls.refID.value) {
        this.stateInchargeForm.controls.StateID.setValue(element.StateID);
        this.onChangeState(element.StateID);
      }
    });
  }

}
