import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-jointsecretoryadd',
  templateUrl: './jointsecretoryadd.component.html',
  styleUrls: ['./jointsecretoryadd.component.scss']
})
export class JointsecretoryaddComponent implements OnInit {

  jointSecretoryForm: FormGroup;
  submited = false;
  stateList: any;
  currentUser: any;
  userID: this;
  issubmited = false;
  jointSecretaryData: any;
  ID: any;
  StateID: any;
  user: any;

  constructor(
    private generalService: GeneralService,
    private cookie: CookieService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = this.generalService.getcurrentUser();
    this.jointSecretoryForm = new FormGroup({
      UserID: new FormControl(this.user.UserID, Validators.required),
      StateID: new FormControl(null, Validators.required),
      Name: new FormControl('', [Validators.required]),
      EmailID : new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9._]+@[A-Za-z0-9]+\.[A-Za-z]{2,3}')]),
      MobileNumber: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{10}')]),
      Des: new FormControl('Joint Secretary', Validators.required)
    });
  }

  ngOnInit() {
    this.getStatelist();
    this.ID = this.route.snapshot.paramMap.get('id');
    this.StateID = this.route.snapshot.paramMap.get('stateid');
    if (this.ID != null && this.StateID != null) {
      const param = `?StateID=${this.StateID}&Des=Joint Secretary`;
      this.generalService.viewStatePresident(param).subscribe((response: any) => {
        this.jointSecretaryData = response.data[0];
        this.jointSecretoryForm.patchValue(this.jointSecretaryData);
        this.jointSecretoryForm.controls.StateID.disable();
      });
    }
  }

  getStatelist() {
    this.generalService
      .getStatesList()
      .subscribe((res: any) => {
        this.stateList = res.data;
      });
  }
  onSubmit() {
    this.submited = true;
    if (this.jointSecretoryForm.valid) {
      this.jointSecretoryForm.controls.StateID.enable();
      this.issubmited = true;
      if (this.ID != null && this.StateID != null) {
        // tslint:disable-next-line: max-line-length
        this.generalService.updateStatePresident(this.jointSecretoryForm.value, this.jointSecretaryData.ID, this.jointSecretaryData.StateID).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Updated',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/jointsecretoryview']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
            this.jointSecretoryForm.controls.StateID.disable();
          }
        });
      } else {
        this.generalService.addStatePresident(this.jointSecretoryForm.value).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Uploaded',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/jointsecretoryview']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
          }
        });
      }
    }
  }

  get f() {
    return this.jointSecretoryForm.controls;
  }
  onChangeState() {
    this.StateID = this.jointSecretoryForm.get('StateID').value;
  }
}
