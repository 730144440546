import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-aiccusersadd',
  templateUrl: './aiccusersadd.component.html',
  styleUrls: ['./aiccusersadd.component.scss']
})
export class AiccusersaddComponent implements OnInit {

  aiccusersForm: FormGroup;
  aiccusersData: any;
  submited = false;
  issubmited = false;
  Des: any;
  StateID: any;
  desID: any;
  userID: this;
  user: any;

  constructor(
    private generalService: GeneralService,
    private cookie: CookieService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = this.generalService.getcurrentUser();
    this.aiccusersForm = new FormGroup({
      UserID: new FormControl(this.user.UserID, Validators.required),
      Name: new FormControl(null, [Validators.required]),
      EmailID : new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9._]+@[A-Za-z0-9]+\.[A-Za-z]{2,3}')]),
      MobileNumber: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{10}')]),
      Des: new FormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this.Des = this.route.snapshot.paramMap.get('des');
    if (this.Des != null) {
      const param = `?Des=${this.Des}`;
      this.generalService.viewStatePresident(param).subscribe((response: any) => {
        this.aiccusersData = response.data[0];
        this.aiccusersForm.patchValue(this.aiccusersData);
        this.aiccusersForm.controls.Des.setValue(this.aiccusersData.Designation);
        this.aiccusersForm.controls.Des.disable();
      });
    }
  }

  onSubmit() {
    this.submited = true;
    if (this.aiccusersForm.valid) {
      this.issubmited = true;
      if (this.Des != null) {
        this.generalService.updateAiccUsers(this.aiccusersForm.value, this.aiccusersData.ID).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Updated',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/aiccusersview']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
            this.aiccusersForm.controls.Des.disable();
          }
        });
      } else {
        this.generalService.addStatePresident(this.aiccusersForm.value).subscribe((data: any) => {
          if (data.status === 200) {
            this.submited = false;
            this.issubmited = true;
            Swal.fire({
              title: 'Uploaded',
              text: data.message,
              type: 'success',
            })
            .then(() => {
              this.router.navigate(['/organogram-user/aiccusersview']);
            });
          } else {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
            });
          }
        });
      }
    }
  }

  get f() {
    return this.aiccusersForm.controls;
  }
}
